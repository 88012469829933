export default {
    validation: {
        required: 'To pole jest wymagane.',
        maxLength: 'Wartość nie może być dłuższa niż {limit} znaków.',
        minLength: 'Wartość nie może być krótsza niż {limit} znaków.',
        email: 'Proszę o podanie prawidłowego adresu email.',
        url: 'Proszę o podanie prawidłowego adresu URL.',
        sameAs: 'Proszę o podanie tej samej wartości ponownie.',
        regex: 'Ta wartość ma niepoprawny format.',
        numeric: 'Proszę o podanie prawidłowego numeru.',
        hexColor: 'Proszę o podanie prawidłowego koloru HEX (np. #000000).',
        decimal: 'Proszę o podanie prawidłowego numeru.',
        minValue: 'Wartość nie może być mniejsza niż {min}.',
    },
    error: {
        unavailableInDemo: 'Ta funkcja jest niedostępna w wersji demo.',
        default: 'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z pomocą techniczną.',
        network: 'Nie można połączyć się z serwerem.',
        notFound: 'Nie można połączyć się z serwerem.',
        unauthorized: 'Brak wymaganych uprawnień.',
        ERROR: '@:error.default',
        BAD_CREDENTIALS: 'Błędne logowanie.',
        BAD_REQUEST: 'Niepoprawne dane.',
        INVALID_REQUEST_DATA: 'Niepoprawne dane.',
        USER_LOCKED: 'Konto użytkownika jest nieaktywne.',
    },
    api_errors: {
        bad_credentials: {
            base: 'Błędne logowanie.',
        },
        conflict: {
            transaction: 'Transaction already verified.',
        },
        not_found: {
            base: 'Nie znaleziono',
            brand: 'Marka nie istnieje',
            buybox: 'Widżet nie istnieje',
            campaign: 'Kampania nie istnieje',
            campaign_api_token: 'Kampania nie ma tokena API. Najpierw wygeneruj jeden.',
            campaign_not_related_buybox: 'Kampania nie jest powiązana z Buyboxem!',
            campaign_space: 'Relacja nie istnieje!',
            category: 'Kategoria nie istnieje',
            commission: 'Prowizja nie istnieje',
            cookie: 'Plik cookie nie istnieje',
            document: 'Dokument nie istnieje',
            manufacturer: 'Producent nie istnieje',
            offer: 'Oferta nie istnieje',
            offer_campaign: 'Kampania ofertowa nie istnieje',
            offer_feed: 'Kanał ofert nie istnieje',
            product: 'Produkt nie istnieje',
            report: 'Raport nie istnieje',
            space: 'Powierzchnia nie istnieje',
            space_no_buybox: 'Powierzchnia nie ma Buyboxa!',
            space_no_category: 'Powierzchnia nie ma kategorii!',
            space_no_token: 'Powierzchnia nie ma tokena API',
            space_token: 'Nie można znaleźć powierzchni',
            subscription: 'Subskrypcja nie istnieje',
            token: 'Token wygasł!',
            transaction: 'Transakcja nie istnieje',
            transaction_invalid_amount: 'Nieprawidłowa kwota transakcji!',
            user: 'Użytkownik nie istnieje',
        },
        unprocessable_entity: {
            amount_below_minimum: 'Kwota poniżej minimum',
            campaign_assign_buybox: 'Kampania nie może zostać przypisać do Buyboxa',
            campaign_assign_commission: 'Prowizja nie jest przypisana do kampanii!',
            email_not_confirmed: 'Email nie jest potwierdzony!',
            locked_user: 'Konto użytkownika jest zablokowane!',
            missing_personal_data: 'Brakujące dane osobowe',
            space_accept: 'Nie możesz zaakceptować tej powierzchni!',
            space_reject: 'Nie możesz odrzucić tej powierzchni!',
            space_subscription: 'Powierzchnia ma aktywną subskrypcję',
            report_not_generated: 'Raport nie jest wygenerowany!',
            user: {
                email: {
                    exist: 'Użytkownik z nazwą użytkownika/adresem e-mail już istnieje!',
                },
                not: {
                    advertiser: 'Użytkownik nie jest reklamodawcą!',
                    publisher: 'Użytkownik nie jest wydawcą!',
                },
            },
            wrong_document_type: 'Nieprawidłowy typ dokumentu',
        },
        access_denied: {
            base: 'Brak dostępu.',
            feed: 'Nie możesz utworzyć kanału dla tego Buyboxa!',
            feature_disabled: 'Funkcja wyłączona',
            functionality_inactive: 'Funkcjonalność nie jest aktywna!',
            token: 'Token wygasł!',
        },
        http: {
            bi_api: 'Problem z API BI',
            database: 'Błąd bazy danych',
            not_implemented: 'Nie zaimplementowano',
        },
        bad_request: {
            amount_gross: "'brutto' nie może być negatywny!",
            amount_net: "'netto' nie może być negatywny!",
            amount_price_limit: 'Dolny limit ceny nie powinien przekraczać górnego limitu',
            campaign_id: 'Nie ustawiono ID kampanii!',
            cookie: 'Nie ustawiono ID pliku cookie!',
            email: 'E-mail nie może być pusty!',
            image_url: 'Nieprawidłowy URL obrazu',
            order: 'Nie ustawiono ID zamówienia!',
            product: {
                commission: {
                    id: 'Nie ustawiono ID prowizji za produkt!',
                },
                gross: 'Nie ustawiono wartości brutto produktu!',
                id: 'Nie ustawiono ID produktu!',
                net: 'Nie ustawiono wartości netto produktu!',
                quantity: 'Nie ustawiono ilości produktu!',
                specification: 'Nieprawidłowa specyfikacja produktów!',
            },
            quantity: {
                value: 'Ilość ma wartość ujemną!',
            },
            query: 'Puste zapytanie',
            refresh_token: 'Brak parametru wyszukiwania',
            required_parameters: 'Brak wymaganych parametrów!',
            response_format: 'Nieobsługiwany format odpowiedzi!',
            search: 'Brak parametru wyszukiwania',
            space_id: 'Nie ustawiono ID miejsca!',
            url_missing: 'Brak parametru adresu URL',
        },
        form: {
            should_be_false: 'Ta wartość powinna być fałszywa.',
            should_be_true: 'Ta wartość powinna być prawdziwa.',
            should_be_type: 'Ta wartość powinna być typu {type}.',
            should_be_blank: 'Ta wartość powinna być pusta.',
            select_wrong_choice: 'Wybrana wartość nie jest prawidłowym wyborem.',
            select_wrong_min_limit: 'Musisz wybrać co najmniej {limit}.',
            select_wrong_max_limit: 'Musisz wybrać co najwyżej {limit}.',
            wrong_values: 'Co najmniej jedna z podanych wartości jest nieprawidłowa.',
            field_not_expected: 'To pole nie było oczekiwane.',
            field_missing: 'Brak tego pola.',
            not_valid_date: 'Ta wartość nie jest prawidłową datą.',
            not_valid_datetime: 'Ta wartość nie jest prawidłową datą i godziną.',
            not_valid_email: 'Ta wartość nie jest prawidłowym adresem e-mail.',
            file_not_found: 'Nie znaleziono pliku.',
            file_not_readable: 'Plik nie jest czytelny.',
            file_not_readable_too_large: 'Plik jest za duży ({size} {suffix}). Dozwolony maksymalny rozmiar to {limit} {suffix}.',
            file_invalid_mimetype: 'Typ MIME pliku jest nieprawidłowy ({type}). Dozwolone typy MIME to {types}.',
            should_be_limit_max: 'Ta wartość powinna wynosić {limit} lub mniej.',
            should_be_long_max: 'Ta wartość jest za długa. Powinna mieć {limit} znaków lub mniej.',
            should_be_limit_min: 'Ta wartość powinna wynosić co najmniej {limit}.',
            should_be_long_min: 'Ta wartość jest za krótka. Powinna mieć {limit} znaków lub więcej.',
            not_blank: 'Ta wartość nie powinna być pusta.',
            not_null: 'Ta wartość nie powinna być pusta.',
            null: 'Ta wartość powinna być pusta.',
            not_valid: 'Ta wartość jest nieprawidłowa.',
            not_valid_time: 'Ta wartość nie jest poprawną godziną.',
            not_valid_url: 'Ta wartość nie jest prawidłowym adresem URL.',
            two_values_equal: 'Obie wartości powinny być równe.',
            file_large_limit: 'Plik jest za duży. Dozwolony maksymalny rozmiar to {limit} {suffix}.',
            file_large: 'Plik jest za duży.',
            file_not_uploaded: 'Nie udało się przesłać pliku.',
            not_valid_number: 'Ta wartość powinna być liczbą.',
            not_valid_image: 'Ten plik nie jest prawidłowym obrazem.',
            not_valid_ip: 'To nie jest prawidłowy adres IP.',
            not_valid_language: 'Ta wartość nie jest prawidłowym językiem.',
            not_valid_locale: 'Ta wartość nie jest poprawną lokalizacją.',
            not_valid_country: 'Ta wartość nie jest prawidłowym krajem.',
            already_used: 'Ta wartość jest już używana.',
            image_size_not_detected: 'Nie można wykryć rozmiaru obrazu.',
            image_width_too_big: 'Szerokość obrazu jest za duża ({width}px). Dozwolona maksymalna szerokość to {max_width}px.',
            image_width_too_small: 'Szerokość obrazu jest za mała ({width}px). Minimalna oczekiwana szerokość to {min_width}px.',
            image_height_too_big: 'Wysokość obrazu jest za duża ({height}px). Dozwolona maksymalna wysokość to {max_height}px.',
            image_height_too_small: 'Wysokość obrazu jest za mała ({height}px). Minimalna oczekiwana wysokość to {min_height}px.',
            should_be_user_current_password: 'Ta wartość powinna być aktualnym hasłem użytkownika.',
            should_have_exactly_limit: 'Ta wartość powinna mieć dokładnie {limit} znaków.',
            file_only_partially_uploaded: 'Plik został przesłany tylko częściowo.',
            file_no_uploaded: 'Żaden plik nie został przesłany.',
            no_temporary_folder: 'W php.ini nie skonfigurowano folderu tymczasowego.',
            temporary_cant_write: 'Nie można zapisać pliku tymczasowego na dysku.',
            PHP_extension_caused_upload_fail: 'Rozszerzenie PHP spowodowało niepowodzenie przesyłania.',
            should_contain_max: 'Ta kolekcja powinna zawierać co najmniej {limit} elementów.',
            should_contain_min: 'Ta kolekcja powinna zawierać {limit} elementów lub mniej.',
            should_contain_exactly: 'Ta kolekcja powinna zawierać dokładnie {limit} elementów.',
            not_valid_card_number: 'Nieprawidłowy numer karty.',
            not_valid_card_number_or_type: 'Nieobsługiwany typ karty lub nieprawidłowy numer karty.',
            not_valid_IBAN: 'To nie jest prawidłowy międzynarodowy numer konta bankowego (IBAN).',
            'not_valid_ISBN-10': 'Ta wartość nie jest prawidłowym numerem ISBN-10.',
            'not_valid_ISBN-13': 'Ta wartość nie jest prawidłowym numerem ISBN-13.',
            'not_valid_ISBN-10_SBN-13': 'Ta wartość nie jest ani poprawnym ISBN-10, ani poprawnym ISBN-13.',
            not_valid_ISSN: 'Ta wartość nie jest prawidłowym numerem ISSN.',
            not_valid_currency: 'Ta wartość nie jest prawidłową walutą.',
            should_be_equal: 'Ta wartość powinna być równa {compared_value}.',
            should_be_greater: 'Ta wartość powinna być większa niż {compared_value}.',
            should_be_greater_or_equal: 'This value should be greater than or equal to {compared_value}.',
            should_be_identical: 'Ta wartość powinna być identyczna z {compared_value_type} {compared_value}.',
            should_be_less: 'Ta wartość powinna być mniejsza niż {compared_value}.',
            should_be_less_or_equal: 'Ta wartość powinna być mniejsza lub równa {compared_value}.',
            should_not_be_qual: 'Ta wartość nie powinna być równa {compared_value}.',
            should_not_be_identical: 'Ta wartość nie powinna być identyczna z {compared_value_type} {compared_value}.',
            image_ratio_too_big: 'Współczynnik proporcji obrazu jest za duży ({ratio}). Dozwolony maksymalny współczynnik proporcji to {max_ratio}.',
            image_ratio_too_small: 'Współczynnik proporcji obrazu jest za mały ({ratio}). Minimalny oczekiwany współczynnik proporcji to {min_ratio}.',
            image_square: 'Obraz jest kwadratowy ({width}x{height}px). Kwadratowe obrazy są niedozwolone.',
            image_landscape_oriented: 'Obraz jest zorientowany poziomo ({width}x{height}px). Obrazy w orientacji poziomej są niedozwolone.',
            image_portrait_oriented: 'Obraz jest zorientowany pionowo ({width}x{height}px). Obrazy w orientacji pionowej są niedozwolone.',
            not_empty: 'Pusty plik jest niedozwolony.',
            wrong_host: 'Nie można znaleźć hosta.',
            wrong_charset: 'Ta wartość nie odpowiada oczekiwanemu zestawowi znaków {charset}.',
            not_valid_BIC: 'To nie jest prawidłowym kodem identyfikacyjny firmy (BIC).',
            error: 'Błąd',
            not_valid_UUID: 'To nie jest prawidłowy UUID.',
            should_be_multiple: 'Ta wartość powinna być wielokrotnością {compared_value}.',
            BIC_not_associated_IBAN: 'Ten kod identyfikacyjny firmy (BIC) nie jest powiązany z IBAN {iban}.',
            should_be_JSON: 'Ta wartość powinna być poprawnym JSON.',
            collection_should_contain_unique_elements: 'Ta kolekcja powinna zawierać tylko unikalne elementy.',
            should_be_positive: 'Ta wartość powinna być dodatnia.',
            should_be_positive_or_zero: 'Ta wartość powinna być dodatnia lub zerowa.',
            should_be_negative: 'Ta wartość powinna być ujemna.',
            should_be_negative_or_zero: 'Ta wartość powinna być ujemna lub zerowa.',
            not_valid_timezone: 'Ta wartość nie jest prawidłową strefą czasową.',
            password_leaked_use_another: 'To hasło zostało ujawnione w wyniku naruszenia danych, nie wolno go używać. Użyj innego hasła.',
            should_be_between: 'Ta wartość powinna wynosić od {min} do {max}.',
            not_valid_hostname: 'Ta wartość nie jest prawidłową nazwą hosta.',
            should_be_multiply: 'Liczba elementów w tej kolekcji powinna być wielokrotnością {compared_value}.',
            should_satisfy_following_constraints: 'Ta wartość powinna spełniać co najmniej jedno z następujących ograniczeń',
            elements_should_have_own_constraints: 'Każdy element tej kolekcji powinien spełniać swój własny zestaw ograniczeń.',
            not_valid_ISIN: 'Ta wartość nie jest prawidłowym międzynarodowym numerem identyfikacyjnym papierów wartościowych (ISIN).',
            should_not_contain_extra_fields: 'Formularz nie powinien zawierać dodatkowych pól.',
            not_valid_EAN: 'Ta wartość nie jest prawidłowym kodem EAN.',
            not_valid_regexp: 'Ta wartość nie jest prawidłowym wyrażeniem regularnym.',
            value_already_used: 'Ta wartość jest już wykorzystana.',
        },
        form_type: {
            birthdate: 'Proszę o podanie prawidłowej daty urodzenia.',
            checkbox: 'To pole ma nieprawidłową wartość.',
            choice: 'Wybrana opcja jest nieprawidłowa.',
            collection: 'Lista jest nieprawidłowa.',
            color: 'Proszę o wybranie prawidłowego koloru.',
            country: 'Proszę o wybranie prawidłowego kraju.',
            currency: 'Proszę o wybranie prawidłownej waluty.',
            date_interval: 'Proszę o wybranie prawidłowego zakresu dat.',
            date_time: 'Proszę o podanie prawidłowej daty i czasu',
            date: 'Proszę o podanie prawidłowej daty.',
            email: 'Proszę o podanie prawidłowego adresu email.',
            file: 'Proszę o wybranie prawidłowego pliku.',
            form: 'Ta wartość jest nieprawidłowa.',
            hidden: 'Ukryte pole jest nieprawidłowe.',
            integer: 'Proszę o podanie wartości liczbowej.',
            language: 'Proszę o wybranie prawidłowego języka.',
            locale: 'Proszę o wybranie prawidłowego regionu.',
            money: 'Proszę o podanie prawidłowej kwoty.',
            password: 'Hasło jest nieprawidłowe.',
            percentage: 'Proszę o podanie prawidłowej wartości procentowej.',
            radio: 'Proszę o wybranie prawidłowej opcji.',
            range: 'Proszę o wybranie prawidłowego zakresu.',
            repeated: 'Wartości nie pasują.',
            search: 'Proszę o podanie prawidłowego wyszukiwanego hasła.',
            time: 'Proszę o podanie prawidłowego czasu.',
            timezone: 'Proszę o wybranie prawidłowej strefy czsowej.',
            ulid: 'Proszę o podanie prawidłowego ULID.',
            url: 'Proszę o podanie prawidłowego URL.',
            week: 'Proszę o podanie prawidłowej wartosci tygodnia.',
        },
        producer_product: {
            ean_already_exists: 'Numer EAN istnieje już w katalogu produktów.',
            sku_already_exists: 'Numer SKU istnieje już w katalogu produktów.',
        },
        products_used_brand: 'Istnieją produkty przypisane do tej marki',
        category_parent: 'Nie możesz usunąć kategorii nadrzędnej',
        products_used_category: 'Istnieją produkty przypisane do tej kategorii',
        brands_used_manufacturer: 'Istnieją marki przypisane do tego producenta',
        variants_used_manufacturer: 'Istnieją warianty przypisane do tego producenta',
        variants_used_product: 'Istnieją marki przypisane do tego produktu',
    },
    language: {
        pl: 'Polski',
        en: 'Angielski',
        lt: 'Litewski',
        lv: 'Łotewski',
        et: 'Estoński',
        de: 'Niemiecki',
        dk: 'Duński',
        fi: 'Fiński',
        fr: 'Francuski',
        nl: 'Niderlandzki',
    },
    topNavBar: {
        accountSettings: 'Ustawienia konta',
        logout: 'Wyloguj',
    },
    adminPanelButton: {
        label: 'Powrót do panelu admina',
    },
    status404: {
        title: '404: Strona nie została odnaleziona',
        description: 'Przykro nam, ale strona, której szukasz nie istnieje lub została przeniesiona.',
        goHome: 'Wróć na stronę główną',
        login: 'Zaloguj się do panelu',
    },
    register: {
        title: 'Utwórz konto 100shoppers',
        headline: 'Przenieś biznes ',
        headlineSpan: 'na wyższy poziom',
        subheadline: 'Rozwiązanie dla producentów',
        label: 'Karta kredytowa nie jest wymagana',
        createAccount: 'Utwórz konto',
        loginBefore: 'Masz już konto?',
        loginLink: 'Zaloguj się',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        businessEmail: 'Adres e-mail',
        password: 'Hasło',
        country: 'Wybierz kraj',
        iAgreeTo: 'Akceptuję',
        termsOfService: 'Regulamin świadczenia usług',
        privacyPolicy: 'Politykę prywatności',
        and: 'i',
        termsError: 'Musisz zaakceptować regulamin i politykę prywatności',
        countries: {
            pl: 'Polska',
            at: 'Austria',
            ee: 'Estonia',
            lt: 'Litwa',
            lv: 'Łotwa',
        },
        links: {
            tos: 'https://100shoppers.com/pl/regulamin/',
            privacy: 'https://100shoppers.com/pl/polityka-prywatnosci/',
        },
    },
    login: {
        title: 'Zaloguj się do konta 100shoppers',
        username: 'Nazwa użytkownika / E-mail',
        password: 'Hasło',
        submit: 'Zaloguj',
        resetPassword: 'Nie pamiętasz hasła? Kliknij ',
        resetPasswordLink: 'tutaj',
        registerBefore: 'Nie masz jeszcze konta?',
        registerLink: 'Zarejestruj się',
        registerUrl: 'https://100shoppers.com/register',
    },
    loginAs: {
        title: '@:login.title',
        missingToken: 'Brak tokena autoryzującego.',
    },
    acceptTerms: {
        title: 'Regulamin',
        description: 'Aby nadal korzystać z konta 100shoppers, musisz zaakceptować {link}.',
        descriptionLink: 'Regulamin',
        accept: 'Akceptuj',
        cancel: 'Anuluj',
    },
    resetPassword: {
        title: 'Zresetuj hasło',
        description: 'Podaj adres e-mail aby rozpocząć resetowanie hasła.',
        email: 'E-mail',
        submit: 'Dalej',
        back: '@:back',
        success: 'Wiadomość została wysłana na Twój adres e-mail, {email}. Postępuj zgodnie ze wskazówkami w e-mailu, aby zresetować hasło.',
    },
    resetPasswordComplete: {
        title: 'Nowe hasło',
        description: 'Wprowadź nowe hasło, aby zakończyć proces resetowania hasła.',
        password: 'Hasło',
        confirmPassword: 'Powtórz hasło',
        submit: '@:save',
        cancel: '@:cancel',
        success: 'Nowe hasło zostało ustawione. Możesz teraz zalogować się do swojego konta za jego pomocą.',
    },
    confirmEmail: {
        title: 'Potwierdź email',
        missingToken: 'Brak tokena weryfikującego.',
        invalidToken: 'Konto zostało już wcześniej aktywowane lub błędnie skopiowano link aktywacyjny do przeglądarki.',
        success: 'Potwierdzono email.',
    },
    accountSettingsPersonal: {
        title: 'Ustawienia osobiste',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'E-mail',
        phone: 'Numer telefonu',
        sectionNotifications: 'Powiadomienia',
        notifications: 'Pakiet powiadomień',
        sectionLanguage: 'Język',
        language: 'Język',
        demo: 'Strona demonstracyjna',
        demoEnabled: 'Aktywna',
        demoDisabled: 'Nieaktywna',
        submit: '@:save',
        saveSuccess: 'Zaktualizowano ustawienia osobiste.',
    },
    accountSettingsCompany: {
        title: 'Ustawienia firmy',
        companyName: 'Nazwa firmy',
        companyNip: 'NIP',
        street: 'Ulica',
        streetNumber: 'Numer budynku',
        apartmentNumber: 'Numer lokalu',
        city: 'Miasto',
        postcode: 'Kod pocztowy',
        country: 'Kraj',
        submit: '@:save',
        saveSuccess: 'Zaktualizowano ustawienia firmy.',
    },
    accountSettingsPassword: {
        title: 'Ustawienia hasła',
        oldPassword: 'Stare hasło',
        newPassword: 'Nowe hasło',
        confirmPassword: 'Powtórz nowe hasło',
        submit: '@:save',
        saveSuccess: 'Zmieniono hasło.',
    },
    producer: {
        title: 'Producent',
    },
    producerSidebar: {
        dashboard: '@:producerDashboard.title',
        products: 'Katalog Produktów',
        reports: 'Raporty',
        reportsShops: 'Sklepy',
        reportsCategory: 'Kategorie',
        reportsProducts: 'Produkty',
        reportsAvailability: 'Dostępność',
        reportsPriceDistribution: 'Rozkład cen',
        reportsSales: 'Sprzedaż',
        campaignAnalytics: 'Analiza kampanii',
        plugin: 'Plugin',
        settings: 'Ustawienia',
        yourPages: 'Twoje strony',
        subscriptions: 'Subskrypcje',
        api: 'Klucze API',
        dataExport: 'Eksport danych',
        help: 'Pomoc',
        helpUrl: 'http://help.100shoppers.com',
        soon: '@:soon',
        productGroups: 'Grupy Produktów',
        competitiveAnalysis: 'Analiza konkurencji',
        campaignAnalysis: 'Analiza kampanii',
        webhook: 'Webhook',
    },
    producerAccountSettings: {
        title: 'Ustawienia konta',
    },
    producerDashboard: {
        title: 'Dashboard',
        impressionsWidget: '@:impressionsWidget',
        impressionsWidgetTooltip:
            'W zależności od wersji widgetu, wyświetlenia są zliczane w momencie, gdy użytkownik wszedł w interakcję z buttonem uruchamiających widget, np. w wersji popover/overlay.',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        clicksTooltip: 'Liczba przejść do sklepów.',
        orders: '@:orders',
        quantity: '@:quantity',
        quantityTooltip: 'Liczba sprzedanych produktów producenta',
        salesAmountOther: '@:salesAmount (inne produkty)',
        salesAmountOtherTooltip: 'Wartość sprzedanych produktów innych producentów.',
        ctr: 'CTR',
        ctrTooltip: 'Współczynnik CTR przejścia do sklepów/wyświetlenia widgetu*100',
        cr: 'CR',
        crTooltip: 'Współczynnik CR liczba transakcji/przejścia do sklepów*100',
        noSpace: '@:spaceSwitch.noResults',
        averageCartValue: 'Średnia wartość koszyka (własna)',
        averageCartValueTooltip: 'Wartość własnej sprzedaży/ Liczbę własnych transakcji',
        transactions: 'Transakcje',
        transactionsTooltip: 'Liczba wszystkich transakcji zrealizowanych za pośrednictewm widgetu.',
        trafficGraphTooltip: '',
        salesGraphTooltip: '',
        quantityOwnProducts: 'Liczba [sztuk] (własne produkty)',
        quantityOwnProductsTooltip: '',
        quantityOtherProducts: 'Liczba [sztuk] (inne produkty)',
        quantityOtherProductsTooltip: '',
        quantityUnknownProducts: 'Liczba [sztuk] (nieznane produkty)',
        quantityUnknownProductsTooltip: '',
        salesAmountOwn: 'Suma sprzedaży (własna)',
        salesAmountOwnTooltip: 'Wartość sprzedanych produktów producenta',
        salesAmountOtherProducts: 'Suma sprzedaży (inne produkty)',
        salesAmountOtherProductsTooltip: '',
        salesAmountUnknownProducts: 'Suma sprzedaży (nieznane produkty)',
        salesAmountUnknownProductsTooltip: '',
        salesAmountUnknown: 'Suma sprzedaży (nieznane produkty)',
        salesAmountUnknownTooltip: '',
    },
    producerPlugin: {
        title: 'Plugin',
        noPlugin: 'Nie ma dostępnej konfiguracji wtyczki.',
    },
    pluginCampaignsList: {
        title: 'Ustawienia listy sklepów',
        noCampaigns: 'Brak dostępnych sklepów.',
        noActiveCampaigns: 'Wybrano wszystkie sklepy',
        noAvailableCampaigns: 'Wybrano wszystkie sklepy',
        activeCampaigns: 'Sklepy wyświetlane na twojej stronie',
        activeCampaignsDescription: 'Zmień kolejność ważności obok sklepu',
        availableCampaigns: 'Dostępne sklepy',
        availableCampaignsDescription: 'Wybierz sklepy, które chcesz wyświetlić, i przeciągnij je w lewo',
        createSuccess: 'Dodano sklep "{name}".',
        updateSuccess: 'Zaktualizowano ważność sklepu "{name}".',
        deleteSuccess: 'Usunięto sklep "{name}".',
    },
    pluginAppearance: {
        title: 'Ustawienia wyglądu i sortowania',
        helpText: 'Zobacz, jak możesz dostosować wygląd pluginu',
        helpUrl: 'https://help.100shoppers.com/pl/articles/4399144-ustawienia-wygladu',
        sectionSorting: 'Sortowanie sklepów',
        noSortTypes: 'Brak dostępnych typów sortowania',
        sectionShoppingList: 'Rozwijana lista sklepów',
        shoppingListDescription: 'Liczba wyświetlanych sklepów. Dodatkowe oferty będą widoczne po kliknięciu "Pokaż więcej".',
        displayedShops: 'Liczba wyświetlanych sklepów',
        leadColor: 'Kolor wiodący',
        buttonLabel: 'Tekst przycisku przejścia do oferty sklepu',
        showProduct: 'Pokaż dane produktu',
        availability: 'Dostępność',
        showPrices: 'Pokaż ceny',
        shopStyle: 'Pokazuj sklep jako',
        shopStyleIconName: 'Ikona i nazwa',
        shopStyleLogo: 'Tylko logo',
        additionalColumnSection: 'Dodatkowa kolumna',
        showAdditionalColumn: 'Pokaż dodatkową kolumnę',
        additionalColumn: 'Treść dodatkowej kolumny (HTML)',
        additionalColumnOnlyForSorted: 'Pokaż tylko dla sortowanych',
        version: 'Wersja widżetu',
        language: 'Język widżetu',
        latestVersion: 'Najnowsza',
        submit: '@:saveSettings',
        saveSuccess: 'Zaktualizowano ustawienia wyglądu i sortowania.',
    },
    pluginCode: {
        title: 'Plugin',
        description: 'Wstaw swój plugin-id w wybranym kodzie i zautomatyzuj wyświetlanie wtyczki „Gdzie kupić”.',
        urlAnchor: 'Jak zaimplementować plugin?',
        url: 'https://help.100shoppers.com/pl/articles/4726202-implementacja-pluginu',
        pluginId: 'Unikalny identyfikator pluginu',
        htmlHelper: '@:code.htmlHelper',
        jsonHelper: '@:code.jsonHelper',
        htmlHelperUrl: '@:code.htmlHelperUrl',
        jsonHelperUrl: '@:code.jsonHelperUrl',
        html: '@:code.html',
        json: '@:code.json',
        copy: '@:code.copy',
        copySuccess: '@:code.copySuccess',
        gtinPlaceholder: '@:code.gtinPlaceholder',
        skuPlaceholder: '@:code.skuPlaceholder',
        namePlaceholder: '@:code.namePlaceholder',
        categoryPlaceholder: '@:code.categoryPlaceholder',
        imagePlaceholder: '@:code.imagePlaceholder',
        pricePlaceholder: '@:code.pricePlaceholder',
        descriptionPlaceholder: '@:code.descriptionPlaceholder',
    },
    pluginExtension: {
        title: 'Rozszerzenie pluginu',
        selectPlaceholder: 'Wybrane rozszerzenie',
        noExtension: 'Brak',
        submit: '@:saveSettings',
        saveSuccess: 'Zaktualizowano ustawienia rozszerzenia.',
        rotatingGroups: {
            extensionHint: 'Dodaj grupy partnerów i ustaw wagi rotujących się sklepów.',
            noPrioritiesWarning: 'Przypisz powyżej wagi sklepom',
            excludeNonStrategicCampaigns: 'Nie pokazuj sklepów bez przypisanej wagi',
            addGroup: 'Dodaj grupę',
            nthGroup: 'Grupa',
            removeGroup: 'Usuń',
            positionFrom: 'Waga od',
            positionTo: 'Waga do',
        },
    },
    producerSpaces: {
        title: 'Twoje strony',
        subtitle: 'Strony',
        total: 'Razem',
        create: 'Utwórz nowy',
        table: {
            name: 'Nazwa',
            url: 'URL',
            category: 'Kategorie',
        },
        noResults: '@:noResults',
        createSuccess: 'Strona „{name}” została utworzona.',
        updateSuccess: 'Strona „{name}” została zaktualizowana.',
        updateWebhookSuccess: 'Webhook dla strony „{name}” został zaktualizowany.',
    },
    producerWebhooks: {
        title: 'Webhook',
        subtitle: 'Webhook',
        total: 'Razem',
        create: 'Utwórz nowy',
        table: {
            name: 'Nazwa',
            url: 'Webhook URL',
        },
        noResults: '@:noResults',
        createSuccess: 'Webhook „{name}” został utworzony.',
        updateSuccess: 'Webhook „{name}” został zaktualizowany.',
    },
    producerProducts: {
        title: 'Produkty',
        filter: 'Znajdź produkt',
        filterPlaceholder: 'Nazwa, numer EAN lub model',
        noResults: 'Brak dostępnych produktów.',
        noResultsDescription: '',
        noSelectedItem: 'Wybierz kategorię lub produkt, aby zobaczyć szczegóły.',
        createProduct: 'Dodaj nowy produkt',
    },
    producerProductDetails: {
        id: 'ID:',
        gtin: 'GTIN:',
        sku: 'SKU:',
        suggestedPrice: 'Cena katalogowa:',
        impressionsWidget: '@:impressionsWidget',
        impressionsWidgetTooltip:
            'W zależności od wersji widgetu, wyświetlenia są zliczane w momencie, gdy użytkownik wszedł w interakcję z buttonem uruchamiających widget, np. w wersji popover/overlay.',
        clicks: '@:clicks',
        clicksTooltip: 'Liczba przejść do sklepów',
        quantity: '@:quantity',
        quantityTooltip: 'Liczba produktów producenta',
        salesAmount: '@:salesAmount',
        salesAmountTooltip: 'Wartość sprzedanych produktów producenta',
        ctr: 'CTR',
        ctrTooltip: 'Współczynnik CTR przejścia do sklepów/wyświetlenia widgetu*100',
        cr: 'CR',
        crTooltip: 'Współczynnik CR liczba sztuk/przejścia do sklepów*100',
        deleteProduct: 'Usuń produkt',
        confirmDelete: 'Czy na pewno usunąć produkt "{name}"?',
        deleteSuccess: 'Usunięto produkt "{name}".',
        editProduct: 'Edytuj produkt',
        addToGroup: 'Dodaj do grupy',
    },
    updateProductModal: {
        updateTitle: 'Edycja produktu "{name}"',
        createTitle: 'Nowy produkt',
        name: 'Nazwa produktu',
        description: 'Opis',
        ean: '@:ean',
        sku: '@:sku',
        price: 'Cena katalogowa',
        images: 'Zdjęcia',
        image: 'Zdjęcie',
        addImage: 'Dodaj zdjęcie',
        category: 'Kategoria',
        categoryRequired: 'Pole "Kategoria" jest wymagane.',
        updateSuccess: 'Zapisano zmiany w produkcie "{name}".',
        createSuccess: 'Dodano produkt {name}.',
    },
    priceDistribution: {
        title: 'Rozkład cen',
        price: '@:price',
        assumedPrice: 'Ostatnio znana cena',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
        noResultsPluginCampaignsIn: 'Cena nie jest dostępna dla sklepów wyświetlanych w widgecie. Zmień ustawienia.',
        noResultsPluginCampaignsNotIn: 'Cena nie jest dostępna dla sklepów niewyświetlanych w widgecie. Zmień ustawienia.',
    },
    priceDeviations: {
        title: 'Odchylenia cen',
        product: 'Produkt',
        recommendedPrice: 'Cena rekomendowana [{currency}]',
        deviationFromRecommendedPrice: 'Odchylenia od ceny rekomendowanej',
        priceDeviationsInTime: 'Odchylenia cen w czasie',
        editPrice: 'Podaj cenę',
        minPrice: 'Cena minimalna',
        maxPrice: 'Cena maksymalna',
        avgPrice: 'Cena',
        deviation: 'Odchylenie',
        noRecommendedPrice: 'Brak ceny rekomendowanej',
    },
    priceDeviationOverTime: {
        title: 'Odchylenia cen w czasie',
        viewBy: 'Wyświetlaj według',
        viewByPrice: 'Kwota [{currency}]',
        viewByChange: 'Zmiana %',
        noRecommendedPrice: 'Brak ceny rekomendowanej',
    },
    productAvailability: {
        title: 'Dostępność',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
    },
    categoryAvailability: {
        title: 'Dostępność',
        offers: 'Liczba ofert',
        pluginCampaigns: '@:filters.pluginCampaigns',
        inPluginCampaigns: '@:filters.inPluginCampaigns',
        notInPluginCampaigns: '@:filters.notInPluginCampaigns',
        allPluginCampaigns: '@:filters.allPluginCampaigns',
    },
    productOffers: {
        title: 'Aktualna lista ofert',
        displayedOffersHeader: 'Wyświetlane na twojej stronie',
        nonDisplayedOffersHeader: 'Niewyświetlane na twojej stronie',
        noResults: 'Brak dostępnych ofert.',
        campaign: 'Sklep',
        offer: 'Oferta',
        price: 'Cena',
        date: 'Data',
        showAll: 'Pokaż wszystkie oferty',
    },
    productCode: {
        title: '@:code.title',
        description: '@:code.description',
        htmlHelper: '@:code.htmlHelper',
        jsonHelper: '@:code.jsonHelper',
        htmlHelperUrl: '@:code.htmlHelperUrl',
        jsonHelperUrl: '@:code.jsonHelperUrl',
        html: '@:code.html',
        json: '@:code.json',
        copy: '@:code.copy',
        copySuccess: '@:code.copySuccess',
        gtinPlaceholder: '@:code.gtinPlaceholder',
        skuPlaceholder: '@:code.skuPlaceholder',
        namePlaceholder: '@:code.namePlaceholder',
        categoryPlaceholder: '@:code.categoryPlaceholder',
        imagePlaceholder: '@:code.imagePlaceholder',
        pricePlaceholder: '@:code.pricePlaceholder',
        descriptionPlaceholder: '@:code.descriptionPlaceholder',
    },
    productLandingPage: {
        title: 'Landing page produktowy',
        description: 'Wykorzystaj poniższy link w swoich social mediach, mailingach, itp.',
        helperText: 'Dowiedz się więcej, jak wykorzystać landing page produktowy?',
        helperUrl: 'https://docs.100shoppers.com/jak-wykorzystac-landing-page-produktowy/',
        previewText: 'Podgląd strony produktowej',
        copyLink: 'Kopiuj link',
        openLink: 'Otwórz link',
        copySuccess: 'Link został skopiowany do schowka.',
    },
    widgetPreview: {
        show: 'Sprawdź, gdzie kupić',
        noOffers: 'Brak ofert',
    },
    producerReportsProducts: {
        title: 'Raporty Produktowe',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsAvailability: {
        title: 'Raporty Dostępności (Nowy)',
        noSpace: '@:spaceSwitch.noResults',
    },
    availabilityStats: {
        avgAvailability: 'Średnia dostępność',
        avgAvailabilityTooltip:
            'Średnia dostępność [%] produktów producenta w sklepach wyświetlanych (ustawienia domyślnie) w widgecie. Aby zmienić zestaw sklepów, użyj filtra Sklep.',
        avgAvailabilityQuantitatively: 'Średnia dostępność (ilościowo)',
        avgAvailabilityQuantitativelyTooltip:
            'Średnia dostępność [ilościowo] produktów producenta w sklepach wyświetlanych (ustawienia domyślnie)w widgecie. Aby zmienić zestaw sklepów, użyj filtra Sklep.',
        products: 'Liczba produktów',
        productsTooltip: 'Liczba produktów producenta.',
    },
    availabilityAnalysis: {
        title: 'Analiza dostępności',
        offersInTime: 'Liczba ofert w czasie',
        offersInTimeTooltip:
            'Wykres prezentuje liczbę ofert w sklepach wyświetlanych w widgecie (domyślne ustawienia). Aby sprawdzić liczbę ofert w pozostałych sklepach, użyj filtra Sklep.',
        offers: 'Liczba ofert',
        avgAvailabilityPerShop: 'Średnia dostępność produktów według sklepów',
        avgAvailabilityPerShopTooltip:
            'Wykres prezentuje ranking według średniej dostępności ofert produktów producenta per sklep [% oraz ilościowo] w sklepach wyświetlanych w widgecie (ustawienia domyślnie). Aby sprawdzić liczbę ofert w pozostałych sklepach, użyj filtra Sklep.',
        sortBySummaryDesc: 'Średnia % malejąco',
        sortBySummaryAsc: 'Średnia % rosnąco',
        sortByChangeDesc: 'Zmiana % (do poprzedniego zakresu) malejąco',
        sortByChangeAsc: 'Zmiana % (do poprzedniego zakresu) rosnąco',
        avgAvailabilityPerProduct: 'Średnia dostępność według produktów',
        avgAvailabilityPerProductTooltip:
            'Wykres prezentuje ranking produktów według średniej dostępności ofert produktów producenta w sklepach wyświetlanych w widgecie (ustawienia domyślnie). Aby sprawdzić liczbę ofert w pozostałych sklepach, użyj filtra Sklep.',
        avgAvailabilityPerProductFirstItemTooltip: 'Po kliknięciu w produkt, możesz sprawdzić dostępność produktu w czasie per sklep.',
        byProductExportProductName: 'Nazwa produktu',
        byProductExportAvgAvailability: 'Dostępność',
        byShopExportShopName: 'Nazwa sklepu',
        byShopExportAvgAvailability: 'Dostępność',
        searchProduct: 'Szukaj produktu',
    },
    availabilityPerShop: {
        title: 'Dostępność w czasie według sklepów',
        availabilityInShops: 'Dostępność w sklepach (dni)',
    },
    currentAvailability: {
        title: 'Aktualna dostępność',
        titleTooltip: 'Zestawienie prezentuje przegląd aktualnej dostępności w sklepach oraz dodatkowo listę niedostępnych produktów.',
    },
    currentUnavailableProducts: {
        title: 'Produkty aktualnie niedostępne',
        lastAvailableAt: 'Ostatnia dostępność',
        unavailableFor: 'Niedostepny przez (liczba dni)',
        productUnavailableTooltip: 'Brak danych na temat ofert produktu w sklepach.',
    },
    producerReportsPriceDistribution: {
        title: 'Reporty Rozkładu Cen',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsShops: {
        title: 'Raporty Sklepów',
        noSpace: '@:spaceSwitch.noResults',
    },
    producerReportsCategory: {
        title: 'Raporty Kategorii',
        noSpace: '@:spaceSwitch.noResults',
    },
    productsSummary: {
        title: 'Podsumowanie',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        search: '@:search',
        shop: '@:filters.shop',
        category: '@:filters.category',
        productName: 'Produkt',
        shopName: 'Sklep',
        sku: 'Model',
        impressionsOffer: '@:impressionsOffer',
        clicks: 'Kliknięcia',
        purchased: 'Ilość',
        price: 'Suma sprzedaży',
        noResults: 'Brak dostępnych produktów.',
        shopsEntrances: 'Przejścia do sklepów',
    },
    categorySummary: {
        title: 'Podsumowanie',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        search: '@:search',
        shop: '@:filters.shop',
        category: '@:filters.category',
        sku: 'Model',
        impressionsOffer: '@:impressionsOffer',
        clicks: 'Kliknięcia',
        purchased: 'Zakupione szt.',
        price: 'Kwota',
        noResults: 'Brak dostępnych kategorii.',
    },
    productsCartAnalysis: {
        title: 'Sprzedane produkty [analiza koszyka]',
        allShops: '@:filters.allShops',
        allConversion: '@:filters.allConversion',
        allBrands: '@:filters.allBrands',
        nConversion: '@:filters.nConversion',
        search: '@:search',
        shop: '@:filters.shop',
        conversion: '@:filters.conversion',
        productName: 'Produkt',
        sku: 'Model',
        brand: '@:brand',
        quantity: '@:quantity',
        amount: '@:salesAmount',
        noResults: 'Brak dostępnych produktów.',
    },
    productsSales: {
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
    },
    productsRanking: {
        title: 'Ranking produktów',
        allShops: '@:filters.allShops',
        allGroups: '@:filters.allGroups',
        search: 'Szukaj produktu',
        rankingType: 'Typ rankingu',
        shop: '@:filters.shop',
        group: '@:filters.group',
        rowsToShow: 'Pokaż',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        sortBy: 'Wyświetlaj według',
        sortBySummary: 'Suma',
        sortByChange: 'Zmiana %',
        noResults: 'Brak dostępnych produktów.',
        exportName: 'Produkt',
        find: 'Znajdź',
    },
    shopsRanking: {
        title: 'Ranking sklepów',
    },
    categoryRanking: {
        title: 'Ranking kategorii',
        allShops: '@:filters.allShops',
        search: 'Szukaj produktu',
        rankingType: 'Typ rankingu',
        shop: '@:filters.shop',
        rowsToShow: 'Pokaż',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        sortBy: 'Wyświetlaj według',
        sortBySummary: 'Suma',
        sortByChange: 'Zmiana %',
        noResults: 'Brak dostępnych kategorii.',
        exportName: 'Produkt',
        find: 'Znajdź',
    },
    shopsTraffic: {
        title: '@:traffic',
        percentageSectionTitle: 'Udział procentowy',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        orders: '@:filters.orders',
    },
    shopsSales: {
        title: '@:sales',
        percentageSectionTitle: 'Udział procentowy',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        quantity: '@:quantity',
        amount: '@:filters.salesAmount',
    },
    categoryTraffic: {
        title: '@:traffic',
        percentageSectionTitle: 'Udział procentowy kategorii',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        tooltip: 'Prezentowane dane domyślnie zawężone są do sklepów wyświetlanych w widgecie.',
    },
    categorySales: {
        title: '@:sales',
        percentageSectionTitle: 'Udział procentowy kategorii',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        shop: '@:filters.shop',
        category: '@:filters.category',
        dataType: '@:filters.type',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
    },
    categoryHeatmap: {
        title: 'Mapa termiczna kategorie/sklepy',
        dataType: '@:filters.type',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:filters.clicks',
        quantity: '@:filters.quantity',
        amount: '@:filters.salesAmount',
        category: 'Kategoria',
        total: 'Suma',
    },
    producerReportsSales: {
        title: 'Raporty sprzedaży',
        noSpace: '@:spaceSwitch.noResults',
        impressionsStatsTooltip:
            'W zależności od wersji widgetu, wyświetlenia są zliczane w momencie, gdy użytkownik wszedł w interakcję z buttonem uruchamiających widget, np. w wersji popover/overlay',
        clicksStatsTooltip: 'Liczba przejść do sklepów',
        ordersStatsTooltip: 'Transakcje zrealizowane za pośrednictwem widgetu.',
        ownOrdersStatsTooltip: 'Transakcje w których przynajmniej jeden produkt to produkt producenta.',
        quantityTooltip: 'Liczba sprzedanych produktów producenta.',
        salesAmountOwnTooltip: 'Wartość sprzedażych produktów producenta',
        averageCartValueTooltip: 'Wartość własnej sprzedaży/ Liczbę własnych transakcji',
    },
    salesSummary: {
        title: 'Podsumowanie',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        noResults: '@:noResults',
        date: '@:date',
        views: '@:impressionsOffer',
        clicks: 'Kliknięcia',
        ctr: 'CTR',
        transaction: 'Transakcje',
        cr: 'CR',
        quantity: 'Liczba [sztuk]',
        value: 'Wartość sprzedaży',
        amount: 'Wartość sprzedaży',
    },
    salesProductDetails: {
        title: 'Szczegóły produktu',
        allShops: '@:filters.allShops',
        allConversion: '@:filters.allConversion',
        allBrands: '@:filters.allBrands',
        nConversion: '@:filters.nConversion',
        search: '@:search',
        shop: '@:filters.shop',
        conversion: '@:filters.conversion',
        clearTransactionFilterLabel: '@:reset',
        date: '@:date',
        transactionId: 'ID Transakcji',
        transactionIdShort: 'ID Trans.',
        productName: 'Produkt',
        sku: 'Model',
        ean: 'EAN',
        brand: '@:brand',
        conversionTime: 'Konwersja',
        quantity: 'Szt.',
        amount: 'Wartość sprzedaży',
        noResults: 'Brak dostępnych produktów.',
        unknownProduct: 'Nieznany',
        unknownProductDescription: 'Produkt nieznany. Może być to usługa wniesienia, rozpakowania, dodatkowa gwarancja, inne.',
        otherProducerProduct: 'Niedostępny',
        otherProducerProductDescription: 'Produkt innego producenta.',
    },
    salesTransactions: {
        title: 'Transakcje',
        allConversion: '@:filters.allConversion',
        allShops: '@:filters.allShops',
        conversion: '@:filters.conversion',
        nConversion: '@:filters.nConversion',
        shop: '@:filters.shop',
        noResults: '@:noResults',
        date: '@:date',
        transactionId: 'ID Transakcji',
        campaign: 'Sklep',
        conversionTime: 'Czas konwersji',
        value: 'Wartość sprzedaży',
        quantity: '@:quantity',
        share: 'Udział %',
        shareOwn: 'własne produkty',
        shareOther: 'inne produkty',
        shareExport: 'Udział % produktów własnych',
        offerPosition: 'Pozycja oferty',
        details: 'Szczegóły transakcji',
        details2: 'Szczegóły<br>transakcji',
        details3: 'Szczegóły',
        source: 'Źródło',
        amount: 'Wartość sprzedaży',
    },
    conversionPath: {
        title: 'ID Transakcji {id} - Ścieżka konwersji',
        clickTime: 'Kliknięcie',
        buyTime: 'Zakup',
        producerPage: 'Strona producenta',
        showPage: 'Strona sklepu',
        clickedOffer: 'Kliknięta oferta',
        ean: '@:ean',
        transaction: 'Transakcja',
        product: 'Produkt',
        brand: '@:brand',
        quantity: '@:quantity',
        unidentifiedProduct: 'Produkt niezidentyfikowany',
        unknownProduct: 'Nieznany',
        unknownProductDescription: 'Produkt nieznany. Może być to usługa wniesienia, rozpakowania, dodatkowa gwarancja, inne.',
        source: 'Źródło',
        transactionSource: 'Źródło transakcji',
    },
    availabilityProductModal: {
        title: 'Dostępność dla {name}',
    },
    productPrices: {
        title: 'Rozkład cen',
        allShops: '@:filters.allShops',
        allCategories: '@:filters.allCategories',
        allGroups: '@:filters.allGroups',
        shop: '@:filters.shop',
        category: '@:filters.category',
        group: '@:filters.group',
        noResults: '@:noResults',
        noProductSelected: 'Wybierz produkt',
        name: 'Nazwa produktu',
        ean: 'EAN',
        minPrice: '@:minPrice',
        avgPrice: '@:avgPrice',
        maxPrice: '@:maxPrice',
        quantity: 'Zakupione sztuk',
    },
    averagePrice: {
        title: 'Średnia cena',
        allMeasures: 'Wszystkie miary',
        measure: 'Miara',
        type: 'Typ',
        byCampaign: 'Po sklepach',
        byCategory: 'Po kategoriach',
        noResults: '@:noResults',
        noProductSelected: 'Wybierz produkt',
        price: '@:price',
        minPrice: '@:minPrice',
        avgPrice: '@:avgPrice',
        maxPrice: '@:maxPrice',
        unavailablePrice: 'Niedostępny w tym czasie',
    },
    averagePriceOverTime: {
        title: 'Średnia cena w czasie',
        price: '@:price',
        assumedPrice: 'Ostatnio znana cena',
        noProductSelected: 'Wybierz produkt',
    },
    quantityOverTime: {
        title: 'Zakupione sztuk w czasie',
        quantity: '@:quantity',
        noProductSelected: 'Wybierz produkt',
        requiredAllCampaignsSelected: 'Wybierz wszystkie sklepy, aby zobaczyć dane',
    },
    filters: {
        reset: '@:reset',
        allShops: 'Wszystkie sklepy',
        allCategories: 'Wszystkie kategorie',
        allGroups: 'Wszystkie grupy produktowe',
        allAvailability: 'Wszystkie dostępności',
        availability: 'Czas Dostępności',
        showAvailability: 'Pokaż czas dostępności',
        allConversion: 'Wszystkie konwersje',
        allBrands: 'Wszystkie marki',
        showSummarized: 'Pokazuj podsumowane wyniki',
        n1Conversion: 'Do godziny',
        nConversion: 'Do {hours} godzin',
        pluginShops: 'Wyświetlane w pluginie',
        otherShops: 'Pozostałe sklepy',
        shop: 'Sklep',
        group: 'Grupa produktowa',
        category: 'Kategoria',
        conversion: 'Konwersja',
        type: 'Typ',
        clicks: '@:clicks',
        orders: '@:orders',
        quantity: '@:quantity',
        salesAmount: '@:salesAmount',
        period: 'Zakres',
        periodDaily: 'Dziennie',
        periodMonthly: 'Miesięcznie',
        periodQuarterly: 'Kwartalnie',
        periodAnnually: 'Rocznie',
        pluginCampaigns: 'Sklepy',
        inPluginCampaigns: 'Wyświetlane w pluginie',
        notInPluginCampaigns: 'Niewyświetlane w pluginie',
        allPluginCampaigns: 'Wszystkie sklepy',
        top: 'Top',
        other: 'Pozostałe',
        showOnlyTopCategories: 'Pokazuj na wykresie tylko top 10 kategorii',
        showProductGroupsResults: 'Pokazuj wyniki dla Grup produktów',
        sortBy: 'Wyświetlaj według',
        sortBySummary: 'Suma',
        sortByChange: 'Zmiana %',
        sources: 'Źródła',
    },
    filterSummary: {
        noResults: 'Brak filtrów',
        types: {
            activeCampaigns: 'Sklepy wyświetlane w pluginie',
            availableCampaigns: 'Pozostałe sklepy',
            categories: 'Kategorie',
            products: 'Produkty',
            conversion: '@:filters.conversion',
            brand: '@:brand',
            transactions: 'Transakcje',
            groups: 'Grupy produktowe',
            aggregations: 'Produkty konkurencyjne',
        },
    },
    table: {
        rowsPerPage: 'Ilość wyników na stronę',
        rowsPerPageAll: 'Wszystkie',
        pageText: '{pageStart}-{pageStop} z {itemsLength}',
        noResults: '@:noResults',
    },
    spaceSwitch: {
        label: 'Strona',
        noResults: 'Brak dostępnych stron',
    },
    dateRangePicker: {
        startDateLabel: 'Data rozpoczęcia',
        endDateLabel: 'Data zakończenia',
        notAvailable: 'niedostępne',
        invalidRange: 'Data rozpoczęcia nie może być późniejsza niż data zakończenia.',
        apply: 'Zastosuj',
        cancel: '@:cancel',
        range: 'Zakres',
        custom: 'Własny',
        days7: 'Ostatnie 7 dni',
        days14: 'Ostatnie 14 dni',
        days30: 'Ostatnie 30 dni',
        today: 'Dziś',
        yesterday: 'Wczoraj',
        currentWeek: 'Bieżący tydzień',
        lastWeek: 'Ostatni tydzień',
        currentMonth: 'Bieżący miesiąc',
        lastMonth: 'Ostatni miesiąc',
        currentQuarter: 'Bieżący kwartał',
        lastQuarter: 'Ostatni kwartał',
        currentYear: 'Bieżący rok',
        lastYear: 'Ubiegły rok',
        comparePrevRange: 'Porównaj z poprzednim zakresem',
        compareLastYear: 'Porównaj do ubiegłego roku',
    },
    graph: {
        export: 'Export',
        fullScreen: 'Pełny ekran',
        exitFullScreen: 'Zamknij pełny ekran',
        activeFilters: 'Aktywne filtry',
    },
    salesGraph: {
        title: '@:sales',
        allShops: '@:filters.allShops',
        shop: '@:filters.shop',
        date: '@:date',
        salesAmount: '@:salesAmount',
        quantity: '@:quantity',
        noResults: '@:noResults',
        tooltip: 'Wykresy prezentują dane dla przedziałów cenowych, w których znajdują się produkty producenta.',
    },
    trafficGraph: {
        title: '@:traffic',
        allShops: '@:filters.allShops',
        shop: '@:filters.shop',
        date: '@:date',
        impressionsOffer: '@:impressionsOffer',
        clicks: '@:clicks',
        noResults: '@:noResults',
    },
    tableHeatmapGraph: {
        title: 'Mapa termiczna',
        noResults: '@:noResults',
    },
    code: {
        title: 'Kod',
        description: 'Wstaw wybrany kod i zautomatyzuj wyświetlanie narzędzia "Gdzie kupić" lub w pełni dostosuj wtyczkę przy użyciu adresu URL JSON.',
        htmlHelper: 'Jak wstawić kod JS?',
        jsonHelper: 'Jak użyć formatu JSON?',
        htmlHelperUrl: 'https://help.100shoppers.com/pl/articles/2814129-javascript-standard',
        jsonHelperUrl: 'https://help.100shoppers.com/pl/articles/3036892-json',
        html: 'HTML',
        json: 'JSON',
        copy: 'Kopiuj',
        copySuccess: 'Kod został skopiowany do schowka.',
        gtinPlaceholder: '_GTIN_numer_produktu_',
        skuPlaceholder: '_unikalny_ID_produktu_producenta_',
        namePlaceholder: '_nazwa_produktu_',
        categoryPlaceholder: '_ścieżka_kategorii_produktu_',
        imagePlaceholder: '_opcjonalny_URL_obrazu_produktu_',
        pricePlaceholder: '_opcjonalna_sugerowana_cena_detaliczna_produktu_',
        descriptionPlaceholder: '_opcjonalny_krótki_opis_produktu_',
    },
    timeShort: {
        days: 'd',
        hours: 'g',
        minutes: 'm',
        seconds: 's',
        quarter: 'Q',
    },
    selectAll: {
        all: 'Wybierz wszystkie',
        others: 'inne',
    },
    productSelector: {
        label: 'Znajdź produkt',
        ean: 'EAN',
        sku: 'SKU',
        productId: 'ID',
        offerId: 'ID Oferty',
        noData: 'Produkt nie został znaleziony.',
        noDataLink: 'Zobacz co możesz zrobić.',
        noDataLinkUrl: 'https://docs.100shoppers.com/jak-zaktualizowac-katalog-produktow/',
    },
    resetFiltersButton: {
        label: 'Przywróć filtry do ustawień domyślnych',
    },
    pieChart: {
        total: 'Suma',
        totalTooltip: 'Suma dla serii danych',
    },
    producerSubscriptions: {
        title: 'Subskrypcje',
        table: {
            permissionName: 'Nazwa subskrypcji',
            status: 'Status',
        },
        noResults: 'Brak dostępnych subskrypcji.',
    },
    permissionContent: {
        title: {
            reportsPriceDistribution: 'Zobacz jak zmieniają się ceny oraz jaki mają wpływ na sprzedaż.',
            reportsAvailability: 'Poznaj dostępność Twoich produktów.',
            reportsShops: 'Zobacz jaki ruch generują Twoi dystrybutorzy.',
            reportsCategories: 'Obserwuj, które z kategorii najlepiej konwertują.',
            reportsProducts: 'Analiza ruchu i sprzedaży Twoich produktów na najwyższym poziomie.',
            reportsSales: 'Zobacz jaki jest procentowy % udziału produktów Twojej marki w poszczególnej transakcji.',
            campaignAnalysis: 'Zobacz analizę kampanii internetowej.',
        },
        description: {
            reportsPriceDistribution:
                'Analizuj:<ul><li>Jak kształtuje się minimalna i maksymalna cena wybranych produktów w sklepach</li><li>Jaki jest procent wzrostu lub spadku ceny produktu w porównaniu z poprzednim okresem</li><li>Jaka jest średnia cena produktu pokazana na osi czasu</li><li>Timeline przedstawiający m.in. średnią cen produktów</li></ul>',
            reportsAvailability:
                'Wiemy, że dostępność Twoich produktów ma znaczący wpływ na sprzedaż i sposób w jaki klienci postrzegają markę.\n\nZobacz jak pokrywa się dostępność produktów w Twojej sieci dystrybucji.\n\nRaport dostarczy Tobie niezbędnej wiedzy:<ul><li>Jak prezentuje się dostępność ofert w czasie w wybranych sklepach</li><li>Na temat średniej dostępności wybranego produktu w poszczególnych sklepach</li></ul>\n\nAktywuj dostęp i poznaj wszystkie zestawienia Twoich produktów.',
            reportsShops: '<ul><li>Obserwuj trendy i zachowania na Twojej stronie</li><li>Analizuj jaki udział % generują wybrane sklepy w poszczególnych kategoriach</li></ul>',
            reportsCategories:
                '<ul><li>Ruch & Sprzedaż według kategorii</li><li>Heatmapa prezentująca sklepy i kategorie</li></ul>\n\nObserwuj jak zmieniają się trendy w poszczególnych kategoriach.',
            reportsProducts:
                '<ul><li>Ranking produktów według interesującej Ciebie wartości (wyświetlenia, kliknięcia, sprzedanych sztuk, wartość) i % zmianą</li><li>Sprzedane produkty z pełną analizą koszyka zakupowego</li></ul>',
            reportsSales:
                '<ul><li>Podsumowanie wielkości sprzedaży</li><li>Szczegółowa analiza transakcji i sprzedanych produktów (Twojej marki lub innych)</li><li>Najważniejsze wskaźniki: CR, CTR i czas konwersji.</li></ul>Tak prezentowane dane pozwolą Tobie na szybką analizę sprzedaży z Twojej strony i wyciągnięcie wniosków.',
            competitiveAnalysis:
                'Już wkrótce dostępny będzie raport konkurencji, który umożliwi Tobie pogłębioną analizę wybranych produktów konkurencyjnych i zbadanie ich pozycji na rynku.',
            campaignAnalysis:
                'Usługę analizy kampanii internetowej <strong>możesz zamówić już teraz</strong>, kontaktując się z Twoim opiekunem. Dzięki temu sprawdzisz, <strong>jakie kanały i reklamy generują największą sprzedaż</strong> oraz zoptymalizujesz budżet reklamowy.\n\nRaport dostarczymy Tobie w interaktywnej formie, a wkrótce analiza będzie dostępna z poziomu panelu.',
        },
        name: {
            stats_price_distribution: 'Raporty rozkładu cen',
            stats_availability: 'Raporty dostepności',
            stats_shops: 'Raporty sklepów',
            stats_categories: 'Raporty kategorii',
            stats_products: 'Raporty produktów',
            stats_sales: 'Raporty sprzedaży',
            stats_competition_report: 'Analiza konkurencji',
            campaign_analysis: 'Analiza kampanii',
        },
        status: {
            active: 'Aktywny',
            canceled: 'Aktywny (do końca miesiąca)',
            inactive: 'Nieaktywny',
        },
        ctaText: {
            competitiveAnalysis: 'Powiadomimy Ciebie, gdy raport będzie dostępny.',
            campaignAnalysis: 'Powiadomimy Ciebie, gdy raport będzie dostępny.',
        },
    },
    activateReportModal: {
        ctaLabel: 'Dowiedz się więcej',
        activateTitle: 'Aktywuj swój dostęp do raportu.',
        activatePayment: 'Opłata zostanie\npobrana co miesiąc.',
        submit: 'Aktywuj',
        success: 'Aktywowano subskrypcję "{name}".',
    },
    cancelSubscriptionModal: {
        title: 'Anuluj subskrypcję',
        ctaLabel: '@:cancelSubscriptionModal.title',
        description: 'Subskrypcja zostanie anulowana z końcem miesiąca.',
        cancel: 'Anuluj',
        submit: '@:save',
        success: 'Anulowano subskrypcję "{name}".',
    },
    dataExport: {
        title: 'Eksportuj dane XLS',
        description:
            'Dane prezentowane w raportach możesz pobrać do pliku XLS. Po zleceniu wygenerowania danych otrzymasz listę dostępnych plików do pobrania. Plik dla każdego miesiąca zawierać będzie wszystkie dostępne dane w zakładkach, z uwzględnieniem wykupionych raportów.',
        generationFeedback: 'Wygenerowanie raportu może chwilę potrwać. Poczekaj lub wróć za moment.',
        availableReports: 'Dostępne zestawienia danych',
        availablePriceReports: 'Dostępne zestawienia danych rozkładu cen',
        availableAvailabilityReports: 'Dostępne zestawienia danych dostępności',
        availableCompetitionReports: 'Dostępne zestawienia danych konkurencji',
        noAvailableReports: 'Brak wygenerowanych zestawień',
        generate: 'Wygeneruj',
        download: 'Pobierz',
        errorTooltip: 'Wystąpił nieoczekiwany błąd. Spróbuj wygenerować raport ponownie za kilka minut.',
    },
    producerProductGroup: {
        title: 'Grupy Produktów',
        createSuccess: 'Grupa „{name}” została utworzona.',
        updateSuccess: 'Grupa „{name}” została zaktualizowana.',
        addProductSuccess: 'Dodano produkt do grupy.',
        deleteProductSuccess: 'Usunięto produkt z grupy.',
        addCategorySuccess: 'Dodano kategorię do grupy.',
        addCategoryNestedSuccess: 'Kategoria „{added}” została dodane. Kategoria „{existing}” już istnieje w utworzonej grupie.',
        deleteCategorySuccess: 'Usunięto kategorię z grupy.',
    },
    groups: {
        title: 'Grupy',
        contains: 'Zawiera',
        containsCategories: 'Kategorie',
        containsProducts: 'Produkty',
        addToGroup: 'Dodaj do grupy',
        removeFromGroup: 'Usuń z grupy',
    },
    competitiveAnalysis: {
        selectedProducts: 'Wybrane produkty',
        createSet: 'Utwórz zestaw',
        saveSetSuccess: 'Zapisano zestaw',
        confirmDelete: 'Czy na pewno usunąć zestaw?',
        productUnavailable: 'Produkt niedostępny',
    },
    competitionProductsSelector: {
        title: 'Utwórz zestaw produktów',
        producerProduct: '1. Wyszukaj swój produkt',
        selectProduct: 'Wybierz produkt',
        addProduct: 'Dodaj kolejny produkt',
        competitiveProductList: '2. Wyszukaj produkty konkurencyjne',
        selectCompetitiveProduct: 'Wybierz produkt konkurencyjny',
        selectGlobal: 'Produkt globalny',
        selectProducer: 'Twój produkt',
        save: 'Zapisz zestaw',
        saveAndCreateNew: 'Zapisz zestaw i utwórz kolejny',
    },
    competitionSets: {
        priceRanking: 'Ranking po cenie',
        priceRankingTooltip: 'Miejsce produktu producenta w stosunku do produktów konkurencyjnych według średniej ceny.',
        avgPriceTooltip: 'Średnia cena produktu z wybranego zakresu czasu w sklepach wyświetlanych w widgecie (ustawienia domyślne).',
        offersRankingTooltip: 'Miejsce produktu producenta w stosunku do produktów konkurencyjnych według liczby ofert.',
        minPriceTooltip: 'Minimalna cena jaka wystąpiła w wybranym okresie.',
        maxPriceTooltip: 'Maksymalna cena jaka wystąpiła w wybranym okresie.',
        offersCount: 'Liczba ofert',
        offersCountTooltip: 'Liczba dostępnych ofert w ostatnim dniu wybranego okresu.',
        offersRanking: 'Ranking po liczbie ofert',
        selectedCompetitiveProducts: 'Wybrane produkty konkurencyjne',
        availability: 'Dostępność',
        editSet: 'Edytuj zestaw',
        removeSet: 'Usuń zestaw',
    },
    competitionAveragePriceOverTime: {
        title: 'Zmiana średniej ceny w czasie',
    },
    competitionPriceRange: {
        title: 'Zakres cen i odchylenia',
        titleTooltip: 'Wykres prezentuje wartości dla ceny minimalnej i maksymalnej oraz odchylenia względem ceny średniej.',
    },
    competitionOffersCount: {
        title: 'Liczba ofert',
        exportName: 'Nazwa produktu',
        exportValue: 'Liczba ofert',
    },
    competitionShopAvailability: {
        title: 'Dostępność w sklepach',
        titleTooltip: 'Ranking pod względem liczby dostępnych ofert w sklepach dla produktu.',
    },
    save: 'Zapisz',
    saveSettings: 'Zapisz ustawienia',
    apply: 'Zastosuj',
    cancel: 'Anuluj',
    delete: 'Usuń',
    back: 'Powrót',
    soon: 'wkrótce',
    more: 'Więcej',
    yes: 'Tak',
    no: 'Nie',
    date: 'Data',
    sales: 'Sprzedaż',
    traffic: 'Ruch',
    price: 'Cena',
    minPrice: 'Cena min',
    avgPrice: 'Średnia cena',
    maxPrice: 'Cena max',
    priceRange: 'Zakres cenowy',
    impressionsWidget: 'Wyświetlenia widgetu',
    impressionsOffer: 'Wyświetlenia ofert',
    clicks: 'Kliknięcia',
    quantity: 'Liczba [sztuk]',
    salesAmount: 'Suma sprzedaży',
    salesValue: 'Wartość sprzedaży',
    orders: 'Zamówienia',
    ctr: 'CTR',
    cr: 'CR',
    noResults: 'Nie znaleziono pasujących wyników.',
    search: 'Szukaj',
    findProduct: 'Znajdź produkt',
    ean: 'EAN',
    sku: 'SKU',
    brand: 'Marka',
    change: 'zmiana',
    reset: 'wyczyść',
    close: 'Zamknij',
    resetSelection: 'Wyczyść zaznaczenie',
    selectAllFrom: 'Zaznacz wszystkie z {date}',
    export: 'Eksport',
    find: 'Znajdź',
    addProductGroup: 'Grupę produktową',
    groupName: 'Nazwa grupy',
    groupType: 'Rodzaj grupy',
    productGroup: 'Grupa produktów',
    categoryGroup: 'Grupa kategorii',
    category: 'Kategoria',
    description: 'Opis',
    findOrSelect: 'Znajdź lub Wybierz',
    add: 'Dodaj',
    categoryList: 'Lista kategorii',
    productList: 'Lista produktów',
    id: 'ID',
    name: 'Nazwa',
    impressions: 'Wyświetlenia',
    ownTransactions: 'Transakcje (własne)',
    allTransactions: 'Transakcje (wszystkie)',
    salesByPriceRange: 'Sprzedaż według przedziałów cenowych',
    priceRanges: 'Przedziały cenowe',
    manufacturerProducts: 'Produkty producenta',
    others: 'Inne',
    everyXCurrency: 'co {value} {currency}',
    numberOfItems: 'Liczba sztuk',
    product: 'Produkt',
    model: 'Model',
    details: 'Szczegóły',
    noProductsAdded: 'Nie dodano produktów',
    noCategoryAdded: 'Nie dodano kategorii',
    confirmDelete: 'Czy na pewno usunąć "{name}"?',
    productGroupsAddCategoryChildExistError:
        'Wybierz całą kategorię "{name}", jeżeli w raportach chcesz wyświetlać produkty dla całej kategorii lub dodaj tylko poszczególne produkty.',
    productGroupsAddProductCategoryExistError: 'Produkt "{name}" już znajduje się w dodanej kategorii',
    productGroupsRemoveProductCategoryExistError: 'Produkt "{name}" już znajduje się w dodanej kategorii. Musisz usunąć całą kategorię.',
    tooltipNewReportIsCreated: 'TWORZY SIĘ NOWY RAPORT',
    rowsToShow: 'Pokaż',
    showAll: 'Pokaż wszystkie',
    new: 'nowy',
    lackOf: 'Brak',
    unknownSource: 'Nieznane',
    subscriptionNotIncludeSourceAnalytics: 'Twoja subskrypcja nie obejmuje sekcji <strong>analizy źródeł</strong>.',
    leaveUsYourDetailsAndWeWillContactYou: 'Zostaw nam swoje dane, a my skontaktujemy się z Tobą.',
    subscriptionNotIncludeCompetitiveAnalytics: 'Twoja subskrypcja nie obejmuje <strong>Raportu konkurencji</strong>',
    contactSalesDepartment: 'Skontaktuj się z działem sprzedaży',
    na: 'Brak danych',
    yourEmail: 'Twój email',
    yourPhoneNumber: 'Twój numer telefonu',
    pleaseContact: 'Proszę o kontakt',
    pleaseContactSent: 'Prośba została wysłana',
    transactions: 'Transakcje',
    salesBySource: 'Sprzedaż per źródło',
    noProductSelected: 'Wybierz produkt',
    recommendedPrice: 'Cena rekomendowana [{currency}]',
    deviation: 'Odchylenie',
    yourSubscriptionDoesNotIncludeThisSection: 'Twoja subskrypcja nie obejmuje tej sekcji',
    availableCurrently: 'Dostępny aktualnie',
    notAvailableCurrently: 'Niedostępny aktualnie',
    failedToLoadProductData: 'Nie udało się wczytać danych produktu',
    noDefinedPrice: 'Brak zdefiniowanej ceny',
    edit: 'Edytuj',
    overview: 'Overview',
    salesSummary2: 'Podsumowanie sprzedaży',
    ownProducts: 'Własne produkty',
    otherProducts: 'Inne produkty',
    unknownProducts: 'Nieznane produkty',
    percent_of_total: '{percent}',
    salesTypeList: {
        own: 'własna',
        others: 'inne',
        unknown: 'nieznane',
        orders: 'ilość',
    },
    subscriptionNotIncludeCampaignAnalytics: 'Twoja subskrypcja nie obejmuje <strong>Analizy kampanii</strong>',
    salesSummaryQuantityItem: 'szt.',
    quantity2: 'Ilość',
    noData: 'Brak danych',
    noDataAvailable: 'Brak danych na temat dostępności czasu oferty.',
    dataFor: 'Dane dla',
    exportingOn: 'Eksportowanie na',
    selectedStores: 'Wybrane sklepy',
    subcategory: 'Podkategoria',
};
